@import '../../assets/variables.scss';

.projectsCont{
    @include contentGeneral;
    // margin: 22px 0;
    .projectList{
        .project{
            margin: 44px auto 66px auto;
            position: relative;
            max-width: 500px;
            opacity: 0;
            transform: translate(20px);
            transition: opacity 0.5s linear, transform 0.5s linear;
            will-change: opacity, transform;
            .overlayCont{
                width: 100%;
                img{
                    width: 100%;
                    height: 100%;
                }
                .info{
                    background-color: #1d1d1d;
                    border: 1px solid $accent1;
                    width: 100%;
                    top: 0;
                    left: 0;
                    border-radius: 10px;
                    overflow: hidden;
                    box-shadow: 11px 11px 30px -8px black;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    h5{
                        font-size: 13px;
                        color: #9a9a9a;
                        margin: 15px 15px 5px 15px;
                        padding-left: 1px;
                        text-align: right;
                    }
                    .infoFlex{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 93%;
                        h4{
                            margin: 0 15px 15px 15px;
                            font-size: $size3;
                            color: $accent1;
                        }
                        p{
                            margin: 15px;
                            color: $light;
                        }
                        .infoBottom{
                            // position: absolute;
                            bottom: 0;
                            font-size: $size1;
                            justify-content: space-between;
                            align-items: flex-end;
                            width: 100%;
                            p{
                                font-size: 13px;
                                color: #9a9a9a;
                                margin-top: 0px;
                            }
                            .links{
                                display: flex;
                                justify-content: flex-end;
                                a{
                                    margin: 15px;
                                    color: $accent1;
                                    font-size: $size4;
                                }
                            }
                        }
                    }
                }
                .imgCont{
                    display: none;
                    border-radius: 10px;
                    overflow: hidden;
                    height: 100%;
                    justify-content: center;
                    img{
                        width: 600px;
                    }
                }
            }
        }
    }
    @media screen and (min-width:$breakMd){
        .projectList{
            .project{
                max-width: 100%;
                .overlayCont{
                    box-shadow: none;
                    max-width: 800px;
                    margin: 0 auto;
                    .info{
                        background-color: #191919e0;
                        z-index: 100;
                        height: auto;
                        width: 500px;
                        left: unset;
                        top: 7%;
                        position: absolute;
                        background-image: none !important;
                        h5{
                            margin: 15px 15px 0 15px;
                        }
                        .infoBottom{
                            position: static;
                            display: flex;
                            p{
                               margin-bottom: 15px;
                            }
                        }
                    }
                    .imgCont{
                        display: flex;
                        position: relative;
                        width: 600px;
                        margin-left: auto;
                        border-radius: 5px;
                        .overlay{
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            opacity: 1;
                            transition: opacity 0.3s linear;
                            background: rgb(224,163,77);
                            background: linear-gradient(135deg, rgba(224,163,77,0) 0%, rgba(224,163,77,0.2679446778711485) 25%, rgba(224,163,77,0.6376925770308124) 50%, rgba(224,163,77,1) 95%);
                            &:hover{
                                opacity: 0;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width:$breakLg){
        width: 1106px;
        margin-left:auto;
        margin-right: auto;
       .projectList{
            .project{
                max-width: 100%;
                .overlayCont{
                    max-width: 1000px;
                }
            }
        }
    
    }
}