@import '../../assets/variables.scss';

.aboutCont{
    @include contentGeneral;
    p{
        position: relative;
        opacity: 0;
        transform: translate(20px);
    }
    .aboutList{
        margin-left: auto;
        margin-top: 22px;
        p{
            margin-bottom: 44px;
        }
        h2{
            position: relative;
            font-size: $size2;
            color: $accent1;
            svg{
                position: absolute;
                left: -13px;
                // color: $light;
            }
        }

    }
    @media screen and (min-width:$breakLg){
        width: 1106px;
        margin-left:auto;
        margin-right: auto;
    }
}