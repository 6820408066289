@import '../../assets/variables.scss';

.contactCont{
    @include contentGeneral;
    p{
        position: relative;
        display: flex;
        flex-direction: column;
        svg{
            font-size: $size4;
        }
        a{
            color: $accent1;
        }
        .socialLinks{
            margin-top: 22px;
            padding-top: 22px;
            display: flex;
            svg{
                margin-right: 44px;
                color: $light;
                &:hover{
                    color: $accent1;
                }
            }
        }
    }
    .flexedForm{
        opacity: 0;
        transform: translate(20px);
        transition: opacity 0.5s linear, transform 0.5s linear;
    }
    .emailToastCont{
        position: fixed;
        bottom: -75px;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: bottom 0.5s linear;
        .emailToast{
            width: 200px;
            margin: 0 auto;
            text-align: center;
            background-color: #1d1d1d;
            padding: 15px;
            border-radius: 10px;
            color: $accent1;
            border: 1px solid $accent1;
            
        }
    }
    form{
        margin-top: 22px;
        label{
            color: $accent1;
            font-size: $size2;
            margin-bottom: 10px;
            display: inline-block;
            font-weight: 200;
        }
        input, textarea{
            display: block;
            width: 100%;
            border: 1px solid $light;
            background-color: #797979;
            font-size: $size2;
            padding: 5px 3px;
            color: $light;
            border-radius: 3px;
            resize: vertical;
        }
        button{
            margin: 22px auto;
            font-size: $size2;
            cursor: pointer;
            transition: background-color 0.3s linear, 0.3s linear;
            &:hover{
                background-color: $accent1;
                color: $mainGrey;
            }
            &:disabled{
                opacity: 0.3;
                pointer-events: none;
            }
        }
        .err{
            display: block;
            text-align: center;
            color: white;
            margin-top: 11px;
            background-color: #ff000063;
            width: 100%;
            margin: 11px auto 0 auto;
            border-radius: 5px;
        }
    }
    .topInputs{
        div{
            margin-bottom: 44px;
            .errEmail{
                background-color: #ff000063;
                color: white;
                font-size: $size1;
                margin-left: 10px;
                padding: 0px;
                border-radius: 5px;
            }
        }
    }
    @media screen and (min-width:$breakSm){
        .flexedForm{
            form{
                .err{
                    width: 321px;
                }
            }
        }
    }
    @media screen and (min-width:$breakMd){
        .topInputs{
            display: flex;
            justify-content: space-between;
            div{
                width: 45%;
            }
        }
    }
    @media screen and (min-width:$breakLg){
        width: 1106px;
        margin-left:auto;
        margin-right: auto;
        .flexedForm{
            display: flex;
            justify-content: space-between;
            p{
                width: 43% !important;
                margin-right: 2%;
            }
            form{
                width: 41.5%;
            }
        }
    }
}