
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	background-color: #3B3B3B;
	min-height: 100%;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

*{
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}
.App{
	overflow: hidden;
	position: relative;
	font-family: 'Quicksand', 'sans-serif';
}

.projectCont{
	background-color: grey;
	padding: 44px 0;
}

.projectSection{
	width: 600px;
	height: 500px;
    margin: 0 auto 44px auto;
	background-color: white;
	position: relative;
	
}

.spinner {
	margin: 0px auto;
	width: 64px;
	height: 40px;
	position: relative;
  }
  
  .cube1, .cube2 {
	background-color: yellow;
	width: 15px;
	height: 15px;
	position: absolute;
	top: 0;
	left: 0;
	
	-webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
	animation: sk-cubemove 1.8s infinite ease-in-out;
  }
  
  .cube2 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
  }
  
  @-webkit-keyframes sk-cubemove {
	25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
	50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
	75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
	100% { -webkit-transform: rotate(-360deg) }
  }
  
  @keyframes sk-cubemove {
	25% { 
	  transform: translateX(42px) rotate(-90deg) scale(0.5);
	  -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	} 50% { 
	  transform: translateX(42px) translateY(42px) rotate(-179deg);
	  -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
	} 50.1% { 
	  transform: translateX(42px) translateY(42px) rotate(-180deg);
	  -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	} 75% { 
	  transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	  -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	} 100% { 
	  transform: rotate(-360deg);
	  -webkit-transform: rotate(-360deg);
	}
  }