@import '../../assets/variables.scss';
.contForResume{
    position: relative;
}
.mainClipCont{
    border-bottom: 4px solid #e0a34d;
    box-sizing: content-box;
    height: 100vh;
    min-height: 575px;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    perspective: 2.5px;
    .parallax{
        background-image:  url('../../assets/city2.jpg');
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateZ(-1.5px) scale(1.7);
        background-size: cover;
        z-index: -1;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: center top;
        will-change: background-position;
    }
    .downArr{
        height: 10px;
        width: 100%;
        z-index: 50;
        position: absolute;
        bottom: 40px;
        text-align: center;
        color: $light;
        font-size: $size4;
        &:hover{
            cursor: pointer;
        }
    }
    .overlay{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        background-color: #3b3b3bc4;
        position: absolute;
        .introBox{
            width: 80%;
            height: 100%;
            .topBox{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                img{
                    width: 300px;
                    border-radius: 50%;
                    margin-top: 44px;
                }
                .introText{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    h1{
                        font-size: $sizeLarge;
                        color: $accent1;
                        text-align: center;
                    }
                    span{
                        border-bottom: 3px solid $light;
                        width: 100%;
                        height: 5px;
                        display: block;
                        margin: 30px 0;
                    }
                    h2{
                        font-size: $size4;
                        color: $accent1;
                        text-align: center;
                    }
                }
            }
        }

        @media screen and (max-height: 800px) and (max-width: $breakMd){
            .introBox{
                .topBox{
                    justify-content: center;
                    img{
                        width: 200px;
                        margin-top: 44px;
                    }
                    .introText{
                        h1{
                            font-size: 50px;
                        }
                        h2{
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    
        @media screen and (min-width:$breakMd){
            .introBox{
                height: auto;
                .topBox{
                    flex-direction: row;
                    justify-content: space-around;
                    .introText{
                        width: 300px;
                    }
                }
            }
        }
        @media screen and(min-width:$breakLg){
            .introBox{
                width: 920px;
                .topBox{
                    .introText{
                        width: 450px;
                    }
                }
            }
        }
    }
}


.resumeCont{
    position: fixed;
    z-index: 100;
    bottom: 0px;
    right: 0px;
    background-color: $accent1;
    padding: 11px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    svg{
        color: $light;
        font-size: $size4;
    }
    @media screen and (min-width: $breakMd){
        background-color: unset;
        position: absolute;
        margin-bottom: 5px;
        svg{
            position: sticky;
            top: 0;
        }
    }
}