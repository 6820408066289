$accent1 : #E0A34D;
$light : #E2E2E2;
$accentWhite : #828282;
$mainGrey : #3B3B3B;

$size1 : 0.8em;
$size2 : 1.2em;
$size3 : 1.8em;
$size4 : 2.2em;
$sizeLarge: 72px;

$breakSm : 450px;
$breakMd : 750px;
$breakLg : 1150px;


@mixin contentGeneral{
    margin: 88px 22px;
    p{
        color: $light;
        margin-top: 22px;
        line-height: 1.5rem;
        margin-left: auto;
        transition: opacity 0.5s linear, transform 0.5s linear;
        will-change: opacity, transform;
        font-family: 'Montserrat', sans-serif;
    }
    button{
        border: 1px solid $accent1;
        background-color: transparent;
        color: $accent1;
        border-radius: 50px;
        display: block;
        font-weight: 200;
        padding: 5px 20px;
        outline: none;
    }

    .titleContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3{
            color: $accent1;
            font-size: $size4;
            margin-right: 22px;
            opacity: 0;
            transition: opacity 1s linear;
            will-change: opacity;
        }
        span{
            display: inline-block;
            border-bottom:1px solid $light;
            width: 100%;
            position: relative;
            transform: translate(100vw);
            transition: transform 0.5s linear;
            will-change: transform;
    
        }
    }

}
