@import '../../assets//variables.scss';
.navCont{
    padding: 11px 0 0 0;
    position: fixed;
    width: 100%;
    z-index: 210;
    color: $light;
    font-size: $size2;
    background-color: transparent;
    transition: all 0.3s linear;

    ul{
        display: flex;
        justify-content: space-around;
        transition: width 0.3s linear;
        li{
            cursor: pointer;
            padding: 11px;
            transition: transform 0.2s linear;
            &:hover{
                transform: translate(5px);
            }
        }
    }
    .navLine{
        height: 1px;
        width: 0%;
        background-color: $light;
        margin-top: 0px;
        position: absolute;
        transition: width 0.5s linear;
    }
    .navResume{
        position: absolute;
        background-color: #9c7235;
        display: none;
        padding: 11px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        z-index: -1;
        top: 49px;
        right: 10px;
        svg{
            color: $light;
            font-size: $size4;
        }
    }
}