@import '../../assets/variables.scss';

.skillsCont{
    @include contentGeneral;
    .svg-inline--fa {
        height: unset;
    }
    p{
        position: relative;
        opacity: 0;
        transform: translate(20px);
    }
    h2{
        color: $light;
        margin: 44px 0 22px auto;
        font-size: 1.5em;
        text-shadow: 0px 0px 3px #E0A34D;
        letter-spacing: 1px;
        padding-bottom: 5px;
        text-align: center;
        @media screen and (min-width: $breakMd){
            text-align: left;
        }
    }
    .skillList{
        margin-top: 22px;
        background-color: transparent;
        display: flex;
        padding: 11px 0px;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 22px;
        .groupContainer{
            margin: 22px 20px;
            width: 35%;
            position: relative;
            transform: translate(20px);
            opacity: 0;
            transition: opacity 0.5s linear, transform 0.5s linear;
            will-change: opacity, transform;
            span{
                margin-top: 5px;
                letter-spacing: 1.3px;
                font-weight: 600;
                display: block;
                width: 100%;
                text-align: center;
                color: $accent1;
                text-shadow: 0px 7px 17px #000000;
                
            }
            .svgContainer{
                width: fit-content;
                margin: 0 auto;
                svg{
                    margin: 0 auto;
                    width: 50px;
                }
            }         
        }
        @media screen and (min-width:$breakSm){
            justify-content: flex-start;
            .groupContainer{
                width: 33%;
                margin: 22px 0;
            }
            
        }  
    }
    @media screen and (min-width:$breakLg){
        width: 1106px;
        margin-left:auto;
        margin-right: auto;
    }
}